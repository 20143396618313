import qs from 'qs';
import axios from 'axios';

// Function to determine the base URL
const determineBaseURL = () => {
    const defaultURL = 'http://127.0.0.1:8000/';

    // Use the development URL in non-production environments
    if (process.env.NODE_ENV !== 'production') {
        return process.env.VUE_APP_URL || defaultURL;
    }

    // For production, construct the URL using the current hostname
    const hostname = typeof window !== 'undefined' ? window.location.hostname : null;
    const protocol = 'https://';

    // Construct and return the base URL
    return hostname ? `${protocol}${hostname}/${process.env.VUE_APP_URL}` : defaultURL;
};

// Create the axios instance with the dynamically determined base URL
const instance = axios.create({
    baseURL: determineBaseURL(),
    headers: {
        'Content-Type': 'application/json',
    },
    paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
});

export default instance;
