import api from './apiService';
import TokenService from './tokenService';

class AuthService {
    async signup( user ) {
        const response = await api.post('/signup', user );
        return response.data; 
    }

    async login({ username, password, remember, type = 'member' }) {
        const response = await api.post('/login', { username, password, remember, type });

        if (response.data.data.accessToken) {
            TokenService.setUser(type, response.data.data);
        }

        return response.data;
    }

    async logout(type) {
        const endpoint = type === 'admin' ? '/admin/logout' : '/login/logout';
        const response = await api.delete(endpoint);

        if (response.status === 200) {
            TokenService.removeUser(type);
        }
        return response;
    }

    async autologin({ token, type = 'member' }) {
        
        const response = await api.post('/autologin', { token });

        if (response.data.data.accessToken) {
            TokenService.setUser(type, response.data.data);
        }

        return response.data;
    }
}

export default new AuthService();
