import camelCase from 'lodash/camelCase';

const modulesCache = {};
const storeModules = {};

function loadModules() {
    const context = require.context('.', true, /^((?!index|\.unit\.).)*\.js$/);
    context.keys().forEach((fileName) => {
        const moduleConfig = context(fileName);

        // Skip if the module is already cached
        if (modulesCache[fileName] === moduleConfig) return;
        modulesCache[fileName] = moduleConfig;

        const modulePath = fileName.replace(/^\.\//, '').replace(/\.\w+$/, '').split(/\//).map(camelCase);
        const moduleName = modulePath.pop();
        const parentNamespace = modulePath.reduce((modules, pathSegment) => {
            if (!modules[pathSegment]) {
                modules[pathSegment] = { modules: {}, namespaced: true };
            }
            return modules[pathSegment].modules;
        }, storeModules);

        parentNamespace[moduleName] = { namespaced: true, ...moduleConfig };
    });
}

function enableHotReload(context) {
    if (module.hot) {
        module.hot.accept(context.id, () => {
            loadModules();
            require('../store').default.hotUpdate({ modules: storeModules });
        });
    }
}

loadModules();
enableHotReload(require.context('.', true, /^((?!index|\.unit\.).)*\.js$/));

export default storeModules;


