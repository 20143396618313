import api from '../../services/apiService';

let fetchPromise = null; // Promise cache for the ongoing API request

export default {
    namespaced: true,
    state: {
        subAccounts: null, // Cache for sub accounts
    },
    mutations: {
        setSubAccounts(state, accounts) {
            state.subAccounts = accounts;
        },
    },
    actions: {
        async fetchSubAccounts({ commit, state }) {
            if (state.subAccounts) {
                // If data is already cached, return it
                return state.subAccounts;
            }

            if (!fetchPromise) {
                fetchPromise = api.get('/login/members/select-tids')
                    .then((response) => {
                        if (response.status === 200 && response.data && response.data.data) {
                            const accounts = Object.values(response.data.data).map(account => {
                                const formattedLabel = `${account.tid} - ${account.label}`;
                                return { value: account.tid, label: formattedLabel };
                            });

                            // Cache the accounts in Vuex.
                            commit('setSubAccounts', accounts);
                            return accounts;
                        }
                        return [];
                    })
                    .catch((error) => {
                        console.error('Error fetching sub accounts:', error);
                        return [];
                    })
                    .finally(() => {
                        // Reset the promise cache.
                        fetchPromise = null;
                    });

            }

            // Return the ongoing fetch promise to all consumers
            return fetchPromise;
        },
    },
};
