import { createWebHistory, createRouter } from 'vue-router';
import authRoutes from './authRoutes';
import adminRoutes from './adminRoutes';
import memberRoutes from './memberRoutes';
import appConfig from '../../app.config'; // Application config for dynamic titles
import store from '../store/store';       // Vuex store

// Combine all route modules into one array
const routes = [
    ...authRoutes,
    ...adminRoutes,
    ...memberRoutes,
    {
        path: '/',
        redirect: '/login', // Redirect root URL to login
    },
    {
        path: '/:pathMatch(.*)*',  // Catch-all route for 404s
        name: 'NotFound',
        component: () => import('../views/404-basic.vue'),
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(_, __, savedPosition) {
        return savedPosition || { top: 0, left: 0 };  // Scroll to top or restore saved position
    },
});

// Utility function to validate a user object
const isValidUser = (user, userType) => {
    const requiredFields = ['userId', 'username', 'type'];
    return (
        requiredFields.every(field => user[field] !== undefined && user[field] !== null) &&
        (user.type === userType || (userType === 'admin' && user.type === 'manager'))
    );
};



// Route guard: Validate routes that require authentication
router.beforeEach(async (to, from, next) => {
    store.dispatch('error/setErrorMessage', null);  // Clear error messages

    // Proceed if the route does not require authentication
    if (!to.matched.some(route => route.meta.authRequired)) {
        return next();
    }

    const isAdminRoute = to.fullPath.includes('/admin');
    const userType = isAdminRoute ? 'admin' : 'member';
    const loggedIn = await store.getters['auth/loggedIn'](userType);

    if (loggedIn) {
        try {
            const validUser = await store.dispatch('auth/validate', userType);
            return isValidUser(validUser, userType) ? next() : redirectToLogin(isAdminRoute);
        } catch {
            return redirectToLogin(isAdminRoute);
        }
    } else {
        return redirectToLogin(isAdminRoute);
    }

    function redirectToLogin(isAdminRoute) {
        next({
            name: isAdminRoute ? 'admin' : 'login'
        });
    }
});

// Route resolver: Handle route meta-data and possible hooks
router.beforeResolve(async (to, from, next) => {
    try {
        for (const route of to.matched) {
            if (route.meta?.beforeResolve) {
                await new Promise((resolve, reject) => {
                    route.meta.beforeResolve(to, from, (...args) => {
                        if (args.length) {
                            next(...args);  // If a redirect is triggered
                            reject(new Error('Redirected'));
                        } else {
                            resolve();
                        }
                    });
                });
            }
        }
    } catch {
        return;
    }

    // Dynamically set page title
    document.title = `${to.name} | ${appConfig.title}`;
    next();
});

export default router;
