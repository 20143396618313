export default [
    {
        path: '/login/dashboard',
        name: 'Member Dashboard',
        component: () => import('../views/frontend/dashboard/index-dashboard.vue'),
        meta: { menu: 'Dashboard', authRequired: true },
    }, {
        path: '/verify/:token',
        name: 'VerifyLink',
        component: () => import('@/views/public/verify-link.vue'),
    },// In your router configuration file (e.g., router.js)
    {
        path: '/members/:id/:token',
        name: 'PasswordReset',
        component: () => import('@/views/public/password-reset.vue')
    }

];
