export default [
    {
        path: '/register',
        name: 'Member SignUp',
        component: () => import('../views/public/member-register.vue')
    },
    {
        path: '/login',
        name: 'Member SignIn',
        component: () => import('../views/public/member-login.vue')
    },
    {
        path: '/forgot-password',
        name: 'Forgot password',
        component: () => import('../views/public/forgot-password.vue')
    },
    {
        path: '/admin',
        name: 'admin',
        component: () => import('../views/public/admin-login.vue')
    },
    {
        path: '/admin/logout',
        name: 'Logout',
        component: () => import('../views/public/admin-logout.vue'),
    },
];
