// store/authModule.js
import AuthService from '../../services/authService';
import TokenService from '../../services/tokenService';

// Retrieve initial admin and member user data
const adminUser = TokenService.getUser('admin');
const memberUser = TokenService.getUser('member');

export const state = () => ({
    adminStatus: {
        loggedIn: !!adminUser,
        user: adminUser || null,
    },
    memberStatus: {
        loggedIn: !!memberUser,
        user: memberUser || null,
    },
});

export const getters = {
    // Getter that takes 'admin' or 'member' as an argument to check login status
    loggedIn: (state) => (type) => {
        if (type === 'admin') {
            return state.adminStatus.loggedIn;
        } else if (type === 'member') {
            return state.memberStatus.loggedIn;
        }
        return false;
    },
    // Check if an admin or member is logged in
    isAdminLoggedIn(state) {
        return state.adminStatus.loggedIn;
    },
    isMemberLoggedIn(state) {
        return state.memberStatus.loggedIn;
    },
    // Get the admin or member user data
    adminUser(state) {
        return state.adminStatus.user;
    },
    memberUser(state) {
        return state.memberStatus.user;
    },
    // Returns the user type ('admin' , manager' or 'member') based on who is logged in
    userType(state) {       
        if (state.adminStatus.loggedIn) return state.adminStatus.user.type;
        if (state.memberStatus.loggedIn) return state.memberStatus.user.type;
        return null;
    },
};

export const mutations = {
    loginSuccess(state, { user, type }) {
        if (type === 'admin') {
            state.adminStatus.loggedIn = true;
            state.adminStatus.user = user.data.user;
        } else if (type === 'member') {
            state.memberStatus.loggedIn = true;
            state.memberStatus.user = user.data.user;
        }
    },
    loginFailure(state, type) {
        if (type === 'admin') {
            state.adminStatus.loggedIn = false;
            state.adminStatus.user = null;
        } else if (type === 'member') {
            state.memberStatus.loggedIn = false;
            state.memberStatus.user = null;
        }
    },
    logout(state, type) {
        if (type === 'admin') {
            state.adminStatus.loggedIn = false;
            state.adminStatus.user = null;
        } else if (type === 'member') {
            state.memberStatus.loggedIn = false;
            state.memberStatus.user = null;
        }
    },
    refreshToken(state, { accessToken, type }) {
        if (type === 'admin') {
            state.adminStatus.user = { ...state.adminStatus.user, accessToken };
        } else if (type === 'member') {
            state.memberStatus.user = { ...state.memberStatus.user, accessToken };
        }
    }
};

export const actions = {
    init({ dispatch }) {
        dispatch('validate');
    },
    signup({ commit }, user) {
        return AuthService.signup(user).then(
            (user) => {
                //commit('loginSuccess', { user, type: 'member' }); 
                return Promise.resolve(user);
            },
            (error) => {
                commit('loginFailure', 'member');
                return Promise.reject(error);
            }
        );
    },
    login({ commit }, { username, password, remember, type }) {
        return AuthService.login({ username, password, remember, type: type || 'member' }).then(
            (user) => {
                commit('loginSuccess', { user, type });
                return Promise.resolve(user);
            },
            (error) => {
                commit('loginFailure', type);
                return Promise.reject(error);
            }
        );
    },
    logout({ commit }, type) {
        return AuthService.logout(type).then(
            (user) => {
                commit('logout', type);
                return Promise.resolve(user);
            },
            (error) => {
                if (error.response && error.response.status === 400) {
                    TokenService.removeUser(type); // Remove the user only for the specified type
                    return Promise.reject(error.response.data);
                }
                return Promise.reject(error);
            }
        );
    },
    async validate({ state }, type) {
        // Check the status of the user based on type and return the user data or null
        const status = type === 'admin' ? state.adminStatus.loggedIn : state.memberStatus.loggedIn;
        if (!status) return Promise.resolve(null);

        return TokenService.getUser(type); // Capitalizes the type
    },
    refreshToken({ commit }, { accessToken, type }) {
        commit('refreshToken', { accessToken, type });
    }
};
