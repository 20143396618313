import api from '../../services/apiService';

let fetchPromise = null; // Cache the promise to avoid multiple API calls

export default {
    namespaced: true,
    state: {
        platforms: null, // Cached managers data
    },
    mutations: {
        setPlatforms(state, platforms) {
            state.platforms = platforms;
        },
    },
    actions: {
        async fetchPlatforms({ commit, state }) {
            // Return cached data if it exists
            if (state.platforms) {
                return state.platforms;
            }

            // If no ongoing request, initiate the API call
            if (!fetchPromise) {
                fetchPromise = api.get('/admin/search/select-platforms').then((response) => {
                    if (response.status === 200 &&
                        response.data?.status === 'success' &&
                        response.data?.data &&
                        typeof response.data.data === 'object') {
                        const platforms = Object.entries(response.data.data).map(([key, value]) => ({
                            value: key,
                            label: value,
                        }));
                        commit('setPlatforms', platforms); // Cache the managers in Vuex
                        return platforms;
                    }
                    return [];
                })
                    .catch((error) => {
                        console.error('Error fetching search platforms:', error);
                        return [];
                    })
                    .finally(() => {
                        fetchPromise = null; // Reset the promise cache
                    });
            }

            return fetchPromise;
        },
    },
};
