import axiosInstance from './apiService';
import TokenService from './tokenService';
import router from '@/router';

const setup = (store) => {
    const retryCounts = {};

    // Helper function to get access token based on user type
    const getAccessToken = (type) => TokenService.getLocalAccessToken(type);

    // Helper function to refresh token based on user type
    const refreshToken = async (type) => {
        try {
            const response = await axiosInstance.post('/refreshToken', {
                refreshToken: TokenService.getLocalRefreshToken(type),
            });

            if (response.data.status === 'success') {
                const { accessToken } = response.data.data;
                store.dispatch('auth/refreshToken', { accessToken, type });
                TokenService.updateLocalAccessToken(type, accessToken);
                return accessToken;
            }
        } catch (error) {
            TokenService.removeUser(type);
            router.push(type === 'admin' ? '/admin' : '/login');
            throw new Error('Token refresh failed');
        }
    };

    // Helper function to handle retry logic
    const canRetry = (config, retryKey) => {
        if (!retryCounts[retryKey]) {
            retryCounts[retryKey] = 0;
        }
        if (retryCounts[retryKey] < 2) {
            retryCounts[retryKey] += 1;
            return true;
        }
        return false;
    };

    axiosInstance.interceptors.request.use(
        (config) => {
            const isAdminRoute = config.url.includes('/admin');
            const userType = isAdminRoute ? 'admin' : 'member';
            const token = getAccessToken(userType);
            if (token) {
                config.headers['Authorization'] = `Bearer ${token}`;
            }

            return config;
        },
        (error) => Promise.reject(error),
    );

    axiosInstance.interceptors.response.use(
        (response) => response, // Pass through if response is successful
        async (error) => {
            const originalConfig = error.config;
            const isAdminRoute = originalConfig.url.includes('/admin');
            const userType = isAdminRoute ? 'admin' : 'member';
            const retryKey = originalConfig.url;

            if (error.response && error.response.status === 401) {
                if (canRetry(originalConfig, retryKey)) {
                    try {
                        const newAccessToken = await refreshToken(userType);
                        if (newAccessToken) {
                            originalConfig.headers['Authorization'] = `Bearer ${newAccessToken}`;
                            return axiosInstance(originalConfig);
                        }
                    } catch (refreshError) {
                        return Promise.reject(refreshError);
                    }
                }
            }

            return Promise.reject(error); // If error is not 401 or retries failed
        },
    );
};

export default setup;
