import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store/store';

import {createBootstrap} from 'bootstrap-vue-next'
import vClickOutside from 'click-outside-vue3';

import '@/assets/scss/config/saas/app.scss';
import '@/assets/scss/mermaid.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import setupInterceptors from './services/setupInterceptors';

setupInterceptors(store);

createApp(App)
    .use(store)
    .use(router)
    .use(createBootstrap())
    .use(vClickOutside)
    .mount('#app');
