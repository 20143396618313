import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store/store';

import { createBootstrap } from 'bootstrap-vue-next';
import vClickOutside from 'click-outside-vue3';
import VueApexCharts from "vue3-apexcharts";

import '@vueform/multiselect/themes/default.css';
import '@/assets/scss/config/saas/app.scss';
import '@/assets/scss/mermaid.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import setupInterceptors from './services/setupInterceptors';

// Import the metadata fetch and update function
import { fetchAndUpdateMetadata } from './utils/metaUtils';

setupInterceptors(store);

// Fetch and update metadata on app load
fetchAndUpdateMetadata();

// Create and mount the Vue app
createApp(App)
    .use(store)
    .use(router)
    .use(VueApexCharts)
    .use(createBootstrap())
    .use(vClickOutside)
    .mount('#app');
